// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/login`,
      register: `${ROOTS.AUTH}/register`,
      forgot: `${ROOTS.AUTH}/forgot-password`,
      reset: `${ROOTS.AUTH}/reset-password`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    inbox: `${ROOTS.DASHBOARD}/inbox`,
    simulator: `${ROOTS.DASHBOARD}/simulador`,
    preRequest: `${ROOTS.DASHBOARD}/nueva-solicitud/pre-solicitud`,
    newRequest: `${ROOTS.DASHBOARD}/nueva-solicitud`,
    requests: `${ROOTS.DASHBOARD}/mis-solicitudes`,
    one: `${ROOTS.DASHBOARD}/one`,
    two: `${ROOTS.DASHBOARD}/two`,
    three: `${ROOTS.DASHBOARD}/three`,
    group: {
      root: `${ROOTS.DASHBOARD}/group`,
      five: `${ROOTS.DASHBOARD}/group/five`,
      six: `${ROOTS.DASHBOARD}/group/six`,
    },
  },
};
