import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/dashboard/home/HomePage'));

// Inbox
const InboxPage = lazy(() => import('src/pages/dashboard/inbox/InboxList'));
const InboxDetails = lazy(() => import('src/pages/dashboard/inbox/InboxDetails'));

// New Request
const NewRequestWrapper = lazy(() => import('src/pages/dashboard/newRequest/NewRequestWrapper'));
const Step0 = lazy(() => import('src/pages/dashboard/newRequest/steps/Step0'));
const Step1 = lazy(() => import('src/pages/dashboard/newRequest/steps/Step1'));
const Step2 = lazy(() => import('src/pages/dashboard/newRequest/steps/Step2'));
const Step3 = lazy(() => import('src/pages/dashboard/newRequest/steps/Step3'));
const Step4 = lazy(() => import('src/pages/dashboard/newRequest/steps/Step4'));
const Step5 = lazy(() => import('src/pages/dashboard/newRequest/steps/Step5'));
const Step6 = lazy(() => import('src/pages/dashboard/newRequest/steps/Step6'));
const Step7 = lazy(() => import('src/pages/dashboard/newRequest/steps/Step7'));
const Step8 = lazy(() => import('src/pages/dashboard/newRequest/steps/Step8'));
const Step9 = lazy(() => import('src/pages/dashboard/newRequest/steps/Step9'));
const Step10 = lazy(() => import('src/pages/dashboard/newRequest/steps/Step10'));
const Step11 = lazy(() => import('src/pages/dashboard/newRequest/steps/Step11'));
const Step12 = lazy(() => import('src/pages/dashboard/newRequest/steps/Step12'));

// Simulator
const SimulatorPage = lazy(() => import('src/pages/dashboard/simulator/SimulatorPage'));
const RequestSummary = lazy(() => import('src/pages/dashboard/simulator/steps/RequestSummary'));

// Applications
const RequestsPage = lazy(() => import('src/pages/dashboard/requests/RequestsList'));
const ApplicationDetails = lazy(() => import('src/pages/dashboard/requests/ApplicationDetails'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      // {
      //  path: 'inbox',
      //   children: [
      //    { element: <InboxPage />, index: true },
      //    { path: ':id', element: <InboxDetails /> }
      //  ]
      // },
      {
        path: 'simulador',
        children: [
          { element: <SimulatorPage />, index: true },
          { path: ':id', element: <RequestSummary /> }
        ],
      },
      {
        path: 'nueva-solicitud',
        element: <NewRequestWrapper />,
        children: [
          { path: 'pre-solicitud', element: <Step0 /> },
          { path: ':random_slug/branch', element: <Step1 /> },
          { path: ':random_slug/convenio', element: <Step2 /> },
          { path: ':random_slug/producto', element: <Step3 /> },
          { path: ':random_slug/biometria', element: <Step4 /> },
          { path: ':random_slug/datos-generales', element: <Step5 /> },
          { path: ':random_slug/datos-generales-2', element: <Step6 /> },
          { path: ':random_slug/domicilio', element: <Step7 /> },
          { path: ':random_slug/datos-laborales', element: <Step8 /> },
          { path: ':random_slug/referencias', element: <Step9 /> },
          { path: ':random_slug/datos-de-solicitud', element: <Step10 /> },
          { path: ':random_slug/vista-previa', element: <Step11 /> },
          { path: ':random_slug/submit', element: <Step12 /> },
        ]
      },
      {
        path: 'mis-solicitudes',
        children: [
          { element: <RequestsPage />, index: true },
          { path: ':id', element: <ApplicationDetails /> }
        ]
      },
      // {
      //   path: 'group',
      //   children: [
      //     { element: <PageFour />, index: true },
      //     { path: 'five', element: <PageFive /> },
      //     { path: 'six', element: <PageSix /> },
      //   ],
      // },
    ],
  },
];
