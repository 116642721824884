import axios from "axios";
import axiosInstance from "./axios";

export const api = {
  auth: {
    login(data){
      return axiosInstance.post(`/api/v1/auth/`, data)
    },
    refresh(data){
      return axiosInstance.post(`/api/v1/auth/refresh/`, data)
    },
    verifyCode(data){
      return axiosInstance.post(`/api/v1/auth/verify/`, data)
    },
    register(data){
      return axiosInstance.post(`/api/v1/auth/register/`, data)
    },
    setPassword(data){
      return axiosInstance.post(`/api/v1/users/users/confirm/`, data)
    },
    resetPassword(data){
      return axiosInstance.post(`/api/v1/users/users/reset/`, data)
    },
    whoami(){
      return axiosInstance.get('/api/v1/users/users/whoami/')
    },
    whoamiNoAuth(token){
      return axios.get(`${process.env.REACT_APP_API}/api/v1/users/users/whoami/`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    }
  },

  dashboard: {
    applications: {
      getAll(params) {
        return axiosInstance.get(`/api/v1/promoter/applications/`, { params });
      },
      getByID(id) {
        return axiosInstance.get(`/api/v1/promoter/applications/${id}/`);
      },
      create(data){
        return axiosInstance.post(`/api/v1/promoter/applications/`, data);
      },
      update(id, data){
        return axiosInstance.patch(`/api/v1/promoter/applications/${id}/`, data)
      },
      put(id, data){
        return axiosInstance.put(`/api/v1/promoter/applications/${id}/`, data)
      },

      documents: {
        create(data) {
          return axiosInstance.post(`/api/v1/users/applicationdocuments/`, data)
        },
        getAll(params) {
          return axiosInstance.get(`/api/v1/users/applicationdocuments/`, { params });
        },
        getByID(id) {
          return axiosInstance.get(`/api/v1/users/applicationdocuments/${id}/`);
        },
        types(params) {
          return axiosInstance.get(`/api/v1/users/documenttypes/`, { params });
        },
        createB64(data){
          return axiosInstance.post(`/api/v1/users/applicationdocumentsbase64/`, data)
        }
      },
    },

    affiliates: {
      getAll(params) {
        return axiosInstance.get(`/api/v1/promoter/affiliates/`, { params });
      },
      getByID(id) {
        return axiosInstance.get(`/api/v1/promoter/affiliates/${id}`)
      }
    },

    products: {
      getAll(params) {
        return axiosInstance.get(`/api/v1/promoter/products/`, { params });
      },

      getByID(id) {
        return axiosInstance.get(`/api/v1/promoter/products/${id}/`);
      },
    },

    productOptions: {
      getByID(id) {
        return axiosInstance.get(`/api/v1/promoter/productoptions/${id}/`);
      },
    },

    countries: {
      getAll(params) {
        return axiosInstance.get(`/api/v1/promoter/countries/`, { params });
      },
    },

    zipCodes: {
      getAll(params) {
        return axiosInstance.get(`/api/v1/promoter/zip-codes/`, { params });
      }
    },

    banks: {
      getAll(params) {
        return axiosInstance.get(`/api/v1/promoter/banks/`, { params });
      },
    },

    branches: {
      getAll(params) {
        return axiosInstance.get(`/api/v1/promoter/branches/`, { params });
      },
    },

    identificationTypes: {
      getAll(params) {
        return axiosInstance.get(`/api/v1/promoter/identificationtypes/`, { params });
      },
    },

    biometricValidation: {
      create(data) {
        return axiosInstance.post(`/api/v1/promoter/biometricvalidations/`, data)
      },
      update(random_slug, data){
        return axios.patch(`${process.env.REACT_APP_API}/api/v1/promoter/biometricvalidations/${random_slug}/`,{ ...data })
      },
      getBySlug(random_slug){
        return axios.get(`${process.env.REACT_APP_API}/api/v1/promoter/biometricvalidations/${random_slug}/`)
      }
    },

    captcha: {
      setToken(data) {
        return axios.post(`${process.env.REACT_APP_API}/api/v1/recaptcha/`, data)
      }
    }
  },

};
