import { lazy, Suspense } from 'react';

// components
import { LoadingScreen } from 'src/components/loading-screen';
// ----------------------------------------------------------------------

const IdentityPage = lazy(() => import('src/pages/identity/IdentityPage'));
// ----------------------------------------------------------------------

export const identityRoutes = [
  {
    path: 'identidad/:random_slug',
    element: <IdentityPage/>
  },
];
