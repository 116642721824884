import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
import { useLocation, useNavigate } from 'react-router';
// components
import { paths } from 'src/routes/paths';
import { useSettingsContext } from 'src/components/settings';
import { AuthContext } from 'src/auth/context/jwt';
//
import Main from './main';
import Header from './header';
import NavMini from './nav-mini';
import NavVertical from './nav-vertical';
import NavHorizontal from './nav-horizontal';
import { ICONS } from './config-navigation';

// ----------------------------------------------------------------------

export default function DashboardLayout({ children }) {
  const settings = useSettingsContext();

  const lgUp = useResponsive('up', 'lg');

  const nav = useBoolean();

  const isHorizontal = settings.themeLayout === 'horizontal';

  const isMini = settings.themeLayout === 'mini';

  const renderNavMini = <NavMini />;

  const renderHorizontal = <NavHorizontal />;

  const renderNavVertical = <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />;

  const navigate = useNavigate();

  const location = useLocation();

  const [bottomNavValue, setBottomNavValue] = useState();

  const context = useContext(AuthContext);

  useEffect(() => {
    if (location.pathname.includes('/inbox')) setBottomNavValue(1);
    else if (location.pathname.includes('/mis-solicitudes')) setBottomNavValue(2);
    else setBottomNavValue(0);
  }, [location]);

  // INTERCOM
  useEffect(() => {
    if(!context?.user) return
    const intercomUser = {
      name: `${context?.user?.promoter?.name}${context?.user?.promoter?.last_name ? ' ' : ''}${context?.user?.promoter?.last_name || ''}`,
      user_id: `WAPP${context?.user?.promoter?.erp_id}`,
      email: context?.user?.email,
      Channelid: 'WEBAPP',
      Promotor: context?.user?.promoter?.erp_id,
      phone: context?.user?.promoter?.mobile_number,
      user_hash: context?.user?.promoter?.intercom_hash
    }
    // eslint-disable-next-line
    Intercom('boot', {
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      api_base: process.env.REACT_APP_INTERCOM_API_BASE,
      hide_default_launcher: true,
      ...intercomUser
    });

    return () => {
      // eslint-disable-next-line
      Intercom('shutdown');
    }
  }, [context]);

  if (isHorizontal) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        {lgUp ? renderHorizontal : renderNavVertical}

        <Main>{children}</Main>
      </>
    );
  }

  if (isMini) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        <Box
          sx={{
            minHeight: 1,
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          {lgUp ? renderNavMini : renderNavVertical}

          <Main>{children}</Main>

          <Box
            width="100%"
            position="fixed"
            bottom={0}
            padding="10px 0"
            sx={{ backgroundColor: 'white' }}
          >
            <BottomNavigation showLabels value={bottomNavValue}>
              <BottomNavigationAction
                onClick={() => navigate(paths.dashboard.root)}
                sx={{ minWidth: '70px', textAlign: 'center' }}
                label="Home"
                icon={ICONS.menuItem}
              />
              <BottomNavigationAction
                sx={{ minWidth: '70px', textAlign: 'center', opacity: 0.5 }}
                label="Inbox"
                icon={ICONS.mail}
                disabled
              />
              <BottomNavigationAction
                onClick={() => navigate(paths.dashboard.requests)}
                sx={{ minWidth: '70px', textAlign: 'center', lineHeight: 1.1 }}
                label="Mis Solicitudes"
                icon={ICONS.blank}
              />
              <BottomNavigationAction
                onClick={() => {
                  window.Intercom('show');
                }}
                sx={{ minWidth: '70px', textAlign: 'center', lineHeight: 1.1 }}
                label="Ayuda"
                icon={ICONS.chat}
              />
            </BottomNavigation>
          </Box>
        </Box>
      </>
    );
  }

  return (
    <>
      <Header onOpenNav={nav.onTrue} />

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        {renderNavVertical}

        <Main>{children}</Main>

        <Box
          width="100%"
          position="fixed"
          bottom={0}
          padding="10px 0"
          sx={{ backgroundColor: 'white' }}
        >
          <BottomNavigation showLabels value={bottomNavValue}>
            <BottomNavigationAction
              onClick={() => navigate(paths.dashboard.root)}
              sx={{ minWidth: '70px', textAlign: 'center' }}
              label="Home"
              icon={ICONS.menuItem}
            />
            {/* <BottomNavigationAction
              sx={{ minWidth: '70px', textAlign: 'center', opacity: 0.5 }}
              label="Inbox"
              icon={ICONS.mail}
              disabled
            /> */}
            <BottomNavigationAction
              onClick={() => navigate(paths.dashboard.requests)}
              sx={{ minWidth: '70px', textAlign: 'center', lineHeight: 1.1 }}
              label="Mis Solicitudes"
              icon={ICONS.blank}
            />
            <BottomNavigationAction
              onClick={() => {
                window.Intercom('show');
              }}
              sx={{ minWidth: '70px', textAlign: 'center', lineHeight: 1.1 }}
              label="Ayuda"
              icon={ICONS.chat}
            />
          </BottomNavigation>
        </Box>
      </Box>
    </>
  );
}


DashboardLayout.propTypes = {
  children: PropTypes.node,
};
