import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { GuestGuard } from 'src/auth/guard';
// layouts
import AuthClassicLayout from 'src/layouts/auth/classic';
// components
import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// JWT
const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));

const JwtRegisterPage = lazy(() => import('src/pages/auth/jwt/RegisterPage'));
const RegisterConfirmCode = lazy(() => import('src/pages/auth/jwt/RegisterConfirmCode'))
const RegisterSuccess = lazy(() => import('src/pages/auth/jwt/RegisterSuccess'))

const ForgotPasswordPage = lazy(() => import('src/pages/auth/password/forgot/ForgotPasswordPage'))
const ForgotPasswordSuccess = lazy(() => import('src/pages/auth/password/forgot/ForgotPasswordSuccess'))
const ForgotPasswordError = lazy(() => import('src/pages/auth/password/forgot/ForgotPasswordError'))

const ResetPasswordPage = lazy(() => import('src/pages/auth/password/ResetPasswordPage'))
const ResetPasswordSuccess = lazy(() => import('src/pages/auth/password/ResetPasswordSuccess'))

// ----------------------------------------------------------------------

const authJwt = {
  path: '',
  element: (
    <GuestGuard>
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    </GuestGuard>
  ),
  children: [
    {
      path: 'login',
      element: (
        <AuthClassicLayout image="/assets/images/auth/loginImage.png">
          <JwtLoginPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: 'register',
      element: (
        <AuthClassicLayout image="/assets/images/auth/registerImage.png">
          <JwtRegisterPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: 'register/success',
      element: (
        <AuthClassicLayout image="/assets/images/auth/registerImage.png">
          <RegisterSuccess />
        </AuthClassicLayout>
      ),
    },
    {
      path: 'register/:id',
      element: (
        <AuthClassicLayout image="/assets/images/auth/registerImage.png">
          <RegisterConfirmCode />
        </AuthClassicLayout>
      ),
    },
    {
      path: 'reset-password/:id',
      element: (
        <AuthClassicLayout>
          <ResetPasswordPage/>
        </AuthClassicLayout>
      )
    },
    {
      path: 'reset-password/success',
      element: (
        <AuthClassicLayout>
          <ResetPasswordSuccess/>
        </AuthClassicLayout>
      )
    },
    {
      path: 'forgot-password',
      element: (
        <AuthClassicLayout>
          <ForgotPasswordPage/>
        </AuthClassicLayout>
      )
    },
    {
      path: 'forgot-password/success',
      element: (
        <AuthClassicLayout>
          <ForgotPasswordSuccess/>
        </AuthClassicLayout>
      )
    },
    {
      path: 'forgot-password/error',
      element: (
        <AuthClassicLayout>
          <ForgotPasswordError/>
        </AuthClassicLayout>
      )
    }
  ],
};

export const authRoutes = [
  {
    path: 'auth',
    children: [authJwt],
  },
];
